import logo from './me.jpg';
import './App.css';
import { SocialIcon } from 'react-social-icons';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Nicholas Markou</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <p>CS @ WPI 2024</p>
        <div className="socials">
          <SocialIcon url="mailto:nmmarkou@wpi.edu" style={{ margin: '.5rem' }} />
          <SocialIcon url="https://github.com/NicholasMarkou" bgColor="#FFFFFF" style={{ margin: '.5rem' }} />
        </div>
        
      </header>
    </div>
  );
}

export default App;
